import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import { Button, NumberBox, SelectBox } from "devextreme-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import pdsGroups from "../../../config/pdsGroups";
import { useAuth } from "../../../hooks/auth";
import useUpdateField from "../../../hooks/useUpdateField";
import CustomCheckbox from "../../Checkbox/index.js";
import TextArea from "../../TextArea";
import { Container } from "./styles";
import { FiInfo } from "react-icons/fi";

export default function Pillars({
  evaluation,
  pillar,
  idEvaluation,
  isEvaluationSenior,
  isSidePanelFullScreen,
  isLoadingGrades,
}) {
  const { hasScope, user } = useAuth();
  const { updateField } = useUpdateField();
  const isSenior = useMemo(() => {
    return hasScope([pdsGroups.Manager, pdsGroups.Director, pdsGroups.Partner]);
  }, [hasScope]);
  const isSales = useMemo(() => hasScope(pdsGroups.Sales), [hasScope]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTooltip, setDialogTooltip] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  const [canViewGrades, setCanViewGrades] = useState(false);
  const [isChecked, setIsChecked] = useState(
    pillar.competences
      .filter((c) => c.isActive)
      .map((c) => {
        const competenceGrade = evaluation.evaluationCompetences.find(
          (info) => info.idCompetence === c.id
        );
        return competenceGrade?.fieldValue === -1;
      })
  );
  const [selectedValues, setSelectedValues] = useState({});

  const generateOptions = useCallback(() => {
    // IDs de seniors para definiçã do valor de incrment de nota
    const specialAreaTypes = [6, 5, 4, 3, 9, 10, 11, 12, 13];

    if (specialAreaTypes.includes(evaluation?.idAreaType)) {
      // Laço para somar 0.25 até 3.
      let options = [];
      for (let i = 0; i <= 3; i += 0.25) {
        options.push({ value: i.toFixed(2), label: i.toFixed(2) });
      }
      return options;
    }

    // Aqui é default, quando precisar mudar para todos é só retornar o array com os values corretos.
    return [
      { value: "-1.0", label: "N/A" },
      { value: "0.0", label: "0.0" },
      { value: "0.5", label: "0.5" },
      { value: "1.0", label: "1.0" },
      { value: "1.5", label: "1.5" },
      { value: "2.0", label: "2.0" },
      { value: "2.5", label: "2.5" },
      { value: "3.0", label: "3.0" },
    ];
  }, [evaluation]);

  const options = useMemo(() => generateOptions(), [generateOptions]);

  const evaluationPillar = useMemo(
    () => evaluation.evaluationPillars.find((p) => p.idPillar === pillar.id),
    [evaluation, pillar]
  );

  const isDisabled = (competence = { description: "" }) => {
    // Partner Evaluation grade can't be edited
    if (
      competence?.description.toLowerCase().replace(/\s+/g, "") ===
      "partnerevaluation"
    ) {
      return true;
    }

    // locked evaluations can't be edited
    if (evaluation.isLocked) {
      return true;
    }

    // If the user is an admin, they can edit everything
    if (hasScope(pdsGroups.Admin)) {
      return false;
    }

    if (
      isSales &&
      evaluation.evaluationStatus.id !== 4 &&
      evaluation.evaluationStatus.id !== 5 &&
      (competence.description.toUpperCase() === "TARGET" ||
        competence.description.toUpperCase() === "REAL")
    ) {
      return false;
    }
    // If the user is viewing their own evaluation and the status is not 5, they can edit
    if (
      user.userId === evaluation?.idProfessional &&
      evaluation.evaluationStatus.id !== 4 &&
      evaluation.evaluationStatus.id !== 5 &&
      isSenior &&
      competence?.description.toLowerCase().replace(/\s+/g, "") ===
        "selfevaluation"
    ) {
      return false;
    }

    // If the user is viewing another person's evaluation, the field is not "self evaluation", and the user is the evaluator or validator, they can edit if the status is not 5
    if (
      user.userId !== evaluation?.idProfessional &&
      competence?.description.toLowerCase().replace(/\s+/g, "") !==
        "selfevaluation" &&
      (user.userId === evaluation.idEvaluator ||
        user.userId === evaluation.idValidator ||
        user.userId === evaluation.idPartner) &&
      evaluation.evaluationStatus.id !== 4 &&
      evaluation.evaluationStatus.id !== 5
    ) {
      return false;
    }

    // In all other cases, the user can't edit
    return true;
  };

  const checkIfUserCanViewGrades = useCallback(() => {
    if (evaluation?.idProfessional === user.userId) {
      if (
        evaluation?.idEvaluationStatus === 4 ||
        evaluation?.idEvaluationStatus === 5
      ) {
        setCanViewGrades(true);
      } else {
        setCanViewGrades(false);
      }
    } else {
      setCanViewGrades(true);
    }
  }, [evaluation, user.userId]);

  const handleSelectChange = useCallback(
    (value, competence) => {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [competence.id]: value,
      }));

      updateField(
        "patch",
        isEvaluationSenior
          ? `/api/evaluations/${idEvaluation}/competences-senior/${competence.id}`
          : `/api/evaluations/${idEvaluation}/competences/${competence.id}`,
        { fieldValue: value },
        "updateGrade"
      );
    },
    [idEvaluation, isEvaluationSenior, updateField]
  );

  const handleTextChange = useCallback(
    (value, competence) => {
      updateField(
        "patch",
        isEvaluationSenior
          ? `/api/evaluations/${idEvaluation}/competences-senior/${competence.id}`
          : `/api/evaluations/${idEvaluation}/competences/${competence.id}`,
        { fieldText: value },
        "updateText"
      );
    },
    [idEvaluation, updateField, isEvaluationSenior]
  );

  const handleChangeNA = useCallback(
    (value, competence) => {
      updateField(
        "patch",
        `/api/evaluations/${idEvaluation}/competences/${competence.id}`,
        { fieldValue: value ? -1 : 0 },
        "updateGrade"
      );
    },
    [idEvaluation, updateField]
  );

  const handleStrenghDevelopmentTextChange = useCallback(
    (pillar, value, type) => {
      updateField(
        "patch",
        `/api/evaluations/${idEvaluation}/pillars/${pillar.id}`,
        { [type]: value },
        "updateText"
      );
    },
    [idEvaluation, updateField]
  );

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleClick = (value) => {
    setOpenDialog(true);
    setDialogTooltip(value.tooltip);
    setDialogTitle(value.title);
  };

  useEffect(() => {
    checkIfUserCanViewGrades();
  }, [checkIfUserCanViewGrades]);

  //

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {pillar && (
        <div className="pillars">
          {pillar.competences
            .filter((c) => c.isActive)
            .map((c, index) => {
              const competenceGrade = evaluation.evaluationCompetences.find(
                (info) => info.idCompetence === c.id
              );
              console.log(competenceGrade?.fieldValue?.toFixed(1));
              return (
                <React.Fragment key={c.id}>
                  {!isEvaluationSenior ? (
                    <div className="overview-grade">
                      <div className="selectbox">
                        <div className="div_tooltip">
                          <h2>
                            {c.description}
                            {c.tooltip && (
                              <button
                                onClick={() => handleClick(c)}
                                style={{
                                  border: "none",
                                  background: "none",
                                  cursor: "pointer",
                                }}
                                disabled={c.tooltip === ""}
                              >
                                <FiInfo color="#8b0304" />
                              </button>
                            )}
                          </h2>
                        </div>
                        <SelectBox
                          items={options}
                          value={
                            selectedValues[c.id] ??
                            competenceGrade?.fieldValue?.toFixed(1)
                          }
                          disabled={isDisabled()}
                          onValueChanged={(event) =>
                            handleSelectChange(event.value, c)
                          }
                          displayExpr="label"
                          valueExpr="value"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="overview-grade grouped">
                      <div className="header">
                        <h2>
                          {c.description}
                          {c.tooltip && (
                            <button
                              onClick={() => handleClick(c)}
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                              disabled={c.tooltip === ""}
                            >
                              <FiInfo color="#8b0304" />
                            </button>
                          )}
                        </h2>

                        <div className="grade">
                          {canViewGrades && (
                            <CustomCheckbox
                              id={c.id}
                              checked={competenceGrade?.fieldValue === -1}
                              label="is N/A?"
                              onChange={(value) => {
                                const newIsChecked = [...isChecked];
                                newIsChecked[index] = Boolean(value);
                                setIsChecked(newIsChecked);
                                handleChangeNA(value, c);
                              }}
                              style={{ fontSize: "0.75rem", gap: 0, width: 50 }}
                              disabled={isDisabled()}
                            />
                          )}
                          <h2
                            style={{
                              width: "2em",
                              height: "1em",
                              textAlign: "center",
                            }}
                          >
                            {isLoadingGrades ? (
                              <CircularProgress size="1em" />
                            ) : !canViewGrades ? (
                              "-"
                            ) : competenceGrade ? (
                              isChecked[index] ||
                              competenceGrade.fieldValue === -1 ? (
                                "N/A"
                              ) : (
                                <>{competenceGrade.fieldValue.toFixed(2)}</>
                              )
                            ) : (
                              ""
                            )}
                          </h2>
                        </div>
                      </div>

                      {c.competenceSeniors?.map((competence) => {
                        if (!competence.isActive) return;
                        const matchedEvaluation =
                          evaluation.evaluationCompetenceSeniors.find(
                            (evaluation) =>
                              evaluation.idCompetenceSenior === competence.id
                          );

                        const selectedValue = options.find(
                          (option) =>
                            option.value == matchedEvaluation?.fieldValue
                        );

                        return (
                          <div key={competence.id} className="competences">
                            <div className="competence">
                              {matchedEvaluation && (
                                <>
                                  <div className="selectbox">
                                    <h2>
                                      {competence.description}
                                      {competence.tooltip && (
                                        <button
                                          onClick={() =>
                                            handleClick(competence)
                                          }
                                          style={{
                                            border: "none",
                                            background: "none",
                                            cursor: "pointer",
                                          }}
                                          disabled={c.tooltip === ""}
                                        >
                                          <FiInfo color="#8b0304" />
                                        </button>
                                      )}
                                    </h2>
                                    {competence.isNumeric ? (
                                      <>
                                        <NumberBox
                                          format="#0.00"
                                          disabled={isDisabled(competence)}
                                          defaultValue={
                                            matchedEvaluation.fieldValue
                                          }
                                          onValueChanged={(event) =>
                                            handleSelectChange(
                                              event.value,
                                              competence
                                            )
                                          }
                                        />
                                      </>
                                    ) : (
                                      competence.isGrade && (
                                        <SelectBox
                                          items={options}
                                          defaultValue={
                                            selectedValue
                                              ? selectedValue.value
                                              : undefined
                                          }
                                          disabled={isDisabled(competence)}
                                          onValueChanged={(event) =>
                                            handleSelectChange(
                                              event.value,
                                              competence
                                            )
                                          }
                                          displayExpr="label"
                                          valueExpr="value"
                                        />
                                      )
                                    )}
                                  </div>

                                  {competence.isText && (
                                    <TextArea
                                      caption="Key Message"
                                      value={matchedEvaluation.fieldText ?? ""}
                                      onChanged={(value) =>
                                        handleTextChange(value, competence)
                                      }
                                      disabled={isDisabled(competence)}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      )}
      {!isEvaluationSenior && (
        <TextArea
          caption="Strengths"
          value={evaluationPillar?.fieldStrength}
          onChanged={(value) =>
            handleStrenghDevelopmentTextChange(pillar, value, "fieldStrength")
          }
          disabled={isDisabled()}
        />
      )}
      {!isEvaluationSenior && (
        <TextArea
          caption="Development"
          value={evaluationPillar?.fieldDevelopment}
          onChanged={(value) =>
            handleStrenghDevelopmentTextChange(
              pillar,
              value,
              "fieldDevelopment"
            )
          }
          disabled={isDisabled()}
        />
      )}
      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>

          <iframe
            src={dialogTooltip}
            style={{
              width: "100%", // Set the width to 100% of the parent's width
              height: "100%", // Set the height to 100% of the parent's height
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleClose}
              color="inherit"
              style={{
                backgroundColor: "#8b0304",
                color: "#ffffff",
                borderRadius: "5px",
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      )}
    </Container>
  );
}
